<template>
  <section>
    <h2 class="titulo-links-escolha">EDITAR SEGMENTO</h2>
    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-6 md:col-6 lg:col-3">
            <label>Nome do Segmento</label>
            <pm-InputText
              v-model="segmentoEscolar.nome"
              :rules="[
                (segmentoEscolar.nome != '' && segmentoEscolar.nome != null) ||
                  'O nome é obrigatório!',
              ]"
            />
          </div>

          <div class="field col-12 md:col-12">
            <h6 style="font-weight: 400; margin-bottom: 20px">
              Selecione uma opção de Funcionalidade do Segmento:
            </h6>
            <fieldset>
              <label>
                <va-radio :option="options[0]" v-model="radiotipo" label="  " />
                Recomendado Educação Infantil:
                <span class="badge bdge1">4 Períodos </span>&nbsp;
                <span class="badge bdge1"> 1 Professor por turma </span>&nbsp;
                <span class="badge bdge1">
                  1 Chamada de Presença por Disciplina
                </span>
                &nbsp;
                <span class="badge bdge1">
                  Formulário de perguntas e respostas.
                </span>
              </label>
              <br />
              <label>
                <va-radio :option="options[1]" v-model="radiotipo" label="  " />
                Recomendado Fundamental I e ERN :
                <span class="badge bdge1">4 Períodos </span>&nbsp;
                <span class="badge bdge1"> 1 Professor por turma </span>&nbsp;
                <span class="badge bdge1">
                  1 Chamada de Presença por Disciplina
                </span>
                &nbsp;
                <span class="badge bdge1">
                  Recuperação Semestral e Recuperação Final.
                </span>
              </label>
              <br />
              <label>
                <va-radio :option="options[2]" v-model="radiotipo" label=" " />
                Recomendado Fundamental II :
                <span class="badge bdge1"> 4 Períodos </span>&nbsp;
                <span class="badge bdge1">
                  1 Professor por disciplina na turma </span
                >&nbsp;
                <span class="badge bdge1">
                  2 Chamadas de Presença por Disciplina </span
                >&nbsp;
                <span class="badge bdge1">
                  Recuperação por Periodo e Recuperação Final.
                </span>
              </label>
              <br />
              <label>
                <va-radio :option="options[3]" v-model="radiotipo" label=" " />
                Recomendado EJA :
                <span class="badge bdge1"> 2 Períodos </span> &nbsp;
                <span class="badge bdge1">
                  Libera a Função de 2 opções de configuração no Período
                </span>
              </label>
            </fieldset>
          </div>
        </div>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button
            label="Voltar"
            class="p-button-danger"
            icon="pi pi-angle-left"
            iconPos="left"
            @click="voltar()"
          />
          <pm-Button
            label="Editar"
            @click="editarSegmento"
            class="p-button-success"
            v-if="segmentoEscolar.nome != null"
            icon="pi pi-check"
            iconPos="right"
          />
        </div>
      </template>
    </pm-Card>
  </section>
</template>

<script>
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import store_token_info from "@/store/store_token_info.js";

export default {
  props: {
    id: {},
  },
  components: {},
  name: "Segmento-Escolar",
  data() {
    return {
      segmentoEscolar: {
        id: null,
        nome: null,
        user_id: store_token_info.usuario_logado,
        tipo: null,
      },
      options: [0, 1, 2, 3],
      radiotipo: 0,
    };
  },
  methods: {
    voltar() {
      this.$router.push({ name: "segmentos" });
    },
    async editarOption(id) {
      try {
        let data = await SegmentoEscolar.obtemUm(id);
        this.segmentoEscolar.id = data.data.id;
        this.segmentoEscolar.nome = data.data.nome;
        this.segmentoEscolar.tipo = data.data.tipo;
        this.radiotipo = data.data.tipo;
      } catch (e) {}
    },
    async editarSegmento() {
      try {
        if (this.radiotipo != null) {
          this.segmentoEscolar.tipo = this.radiotipo;
        }

        const data = await SegmentoEscolar.alterar(this.segmentoEscolar);

        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "success",
          duration: 2500,
          fullWidth: false,
        });
        this.$router.push({ name: "segmentos" });
      } catch (e) {
        Object.entries(e.response.data.validacao).forEach(([key, value]) => {
          this.$vaToast.init({
            message: value[0],
            iconClass: "fa-star-o",
            position: "top-right",
            color: "danger",
            duration: 2500,
            fullWidth: false,
          });
        });
      }
    },
  },
  async beforeMount() {
    if (this.id == null) {
      this.$router.push({ name: "segmentos" });
    } else {
      this.editarOption(this.id);
    }
  },
};
</script>

<style>
.row.row-inside {
  max-width: none;
}

.form-control:focus {
  background-color: #f6f7f6;
  border-color: #673ab7;
  box-shadow: none;
}
.form-control {
  background-color: #f6f7f6;
  border: 1px solid #f6f6f6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.va-input-wrapper,
fieldset {
  margin-bottom: 0.5rem;
}
</style>
